
import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../../lib/abpbase";
import EmailTemplateDynamicProperty from "@/store/entities/notificationcontext/email-template-dynamic-property";

@Component
export default class EmailTemplateDynamicPropertyForm extends AbpBase {
  @Prop({default: new EmailTemplateDynamicProperty()}) dynamicProperty: EmailTemplateDynamicProperty;
  @Prop({default: 0}) index: number;

  removeDynamicProperty() {
    this.$emit('remove', this.index);
  }

}
